import { useSearchParams } from "react-router-dom"
import { useState, useEffect } from "react"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import UserInfoPanel from "../../components/UserInfoPanel"
import useAPI from "../../hooks/common/useAPI"
import { ReactComponent as SVGPay } from "../../svg/pay.svg"
import InnerHeader from "../../components/InnerHeader"
import useSDK from "../../hooks/data/useSDK"
import { Loading, SpinLoading, Toast } from "antd-mobile"

const BoxBar = ({ title, value, backgroundColor }) => {
    return <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: 'center', padding: "0.14rem 0.1rem", backgroundColor: backgroundColor, borderBottom:"2px" }}>
        <div style={{ fontSize: "0.14rem", color: "#444" }}>{title}</div><div style={{ fontSize: "0.13rem", color: "#666" }}>{value}</div>
    </div>
}

export default () => {

    const { request } = useAPI()

    const [userinfo, setUserinfo] = useState()

    const { getAppid, openUrl } = useSDK()

    const [isLoading, setIsLoading] = useState(false)

    const getUserinfo = async () => {

        let rs = await request("/user/info")
        if (rs?.result === true) {
            setUserinfo(rs?.data)
            return true
        } else {
            Toast.show(rs?.message)
            return false
        }

    }

    useEffect(() => {
        getUserinfo();
    }, [])


    const [params] = useSearchParams()

    const money = params.get("money")
    const pay_money = params.get("pay_money")
    const pay_channel_id = params.get("pay_channel_id")
    const money_id = params.get("money_id")
    const paytype = params.get("paytype")
    console.log(money, pay_money, pay_channel_id, money_id)

    const submit = async () => {
        setIsLoading(true);
        try {
            let rs = await request("/pay/trade_recharge", {
                app_id: getAppid(),
                pay_channel_id: pay_channel_id,
                money_id: money_id
            })
            setIsLoading(false)

            if (rs?.result === true) {
                openUrl(rs?.data?.pay_url)

            } else {
                Toast.show(rs?.message)

            }
        } catch (ex) {
            setIsLoading(false)
        }

    }

    return <div >
        {/* <Header /> */}
        {/* <InnerHeader title={"確認訂單"} /> */}

        {isLoading === true && <div className="loading" style={{ position: "fixed", left: 0, right: 0, top: 0, bottom: 0, zIndex: 10 }} >
            <div style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, backgroundColor: "white", opacity: 0.5 }}></div>
            <div style={{ textAlign: "center", position: "relative", width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center' }}>
                <SpinLoading color={`rgba(0,154,214,1)`} />
                <div style={{ color: "rgba(0,154,214,1)", fontWeight: 'bold', marginTop: "0.16rem" }}>正加加载 ...</div>
            </div>
        </div>}
        <div style={{ filter: isLoading === true ? "blur(3px)" : "none" }}>
            <UserInfoPanel
                onRefresh={async () => {
                    let rs = await getUserinfo();
                    if (rs) Toast.show('信息已更新')
                }}
                name={userinfo?.username} coins={(userinfo?.money / 100).toFixed(2)} face_img={require("../../images/faceimg.png")} />
            <div style={{ width: "96%", margin: "auto", marginTop: "0.2rem", fontSize: "0.16rem", fontWeight: "bold" }}>訂單確認</div>
            <div style={{ width: "96%", margin: 'auto', marginTop: "0.2rem", borderRadius: "0.06rem", boxSizing: 'border-box' }}>
            {/* <div style={{ width: "96%", margin: 'auto', border: "2px solid rgba(0,154,214,0.5)", marginTop: "0.2rem", borderRadius: "0.06rem", boxSizing: 'border-box' }}> */}
                <BoxBar title={'充值用戶'} value={userinfo?.username}  />
                <BoxBar title={'付款方式'} value={<span style={{ fontWeight: "bold" }}>{paytype}</span>} backgroundColor={`#fff`} />
                <BoxBar title={'消費金額'} value={<span style={{ fontWeight: "bold", fontSize: "0.16rem", color:"#fe7a2e" }} >{(pay_money / 100).toFixed(2)}元</span>} />
                <BoxBar title={'獲得金額'} value={<span style={{ fontWeight: "bold" }}>{(money / 100).toFixed(2)}</span>} backgroundColor={`#fff`} />
            </div>

            <div className="primary-bg btn" onClick={submit} style={{background:"#fe9438",
                width: "3rem", margin: 'auto', marginTop: "0.4rem", color: "white", display: 'flex', flexDirection: "row"
                , padding: "0.08rem 0rem", justifyContent: 'center', borderRadius: "0.04rem", lineHeight: "0.26rem", boxSizing: 'border-box', marginBottom: "0.2rem"
            }}> <SVGPay style={{ width: "0.26rem", height: "0.26rem" }} fill="#fff" /> 立即付款</div>



            <Footer />
        </div>
    </div>
}