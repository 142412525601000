import React, { useEffect } from 'react';
import useAPI from './hooks/common/useAPI';
import './index.css';
import Router from './pages/Router';
import reportWebVitals from './reportWebVitals';

import ReactClient from "react-dom/client"
import ReactDOM from "react-dom"
import useSDK from './hooks/data/useSDK';
import useTj from './hooks/common/useTj';



//日期运算
Date.prototype.format = function (format) {
  if (!isNaN(this.valueOf())) {

    if (typeof (format) != "string") return this.toLocalString();
    var year = this.getFullYear().toString();
    var month = (this.getMonth() + 1).toString();;
    var date = this.getDate().toString();;
    var hour = this.getHours().toString();
    var minutes = this.getMinutes().toString();
    var sencond = this.getSeconds().toString();
    var ms = this.getMilliseconds().toString();
    format = format.replace("yyyy", year);
    format = format.replace("MM", month.length == 1 ? "0" + month : month);
    format = format.replace("dd", date.length == 1 ? "0" + date : date);
    format = format.replace("HH", hour.length == 1 ? "0" + hour : hour);
    format = format.replace("mm", minutes.length == 1 ? "0" + minutes : minutes);
    format = format.replace("ss", sencond.length == 1 ? "0" + sencond : sencond);
    format = format.replace("ff", ms.length == 1 ? "000" + ms : ms.length == 2 ? "00" + ms : ms.length == 3 ? "0" + ms : ms);
    format = format.replace("yy", year[2] + year[3]);
    format = format.replace("M", month);
    format = format.replace("d", date);
    format = format.replace("h", hour);
    format = format.replace("m", minutes);
    format = format.replace("s", sencond);
    format = format.replace("f", ms);
    return format;
  } else {
    return "";
  }

};


window.getQueryStringByName = (name) => {

  var result = window.location.hash.match(new RegExp("[\?\&]" + name + "=([^\&]+)", "i"));

  if (result == null || result.length < 1) {

    return "";

  }

  return result[1];

}


let token = decodeURIComponent(window.getQueryStringByName("token"));
let app_id = decodeURIComponent(window.getQueryStringByName("app_id"));

// console.log('token', token)
// console.log('token app id ', app_id)



const App = () => {

  const { setToken, setAppid } = useSDK()
  const { loadTj } = useTj();


  useEffect(()=>{
    loadTj()
  },[])

  useEffect(() => {

    if (token) {
      setToken(token)
    }
    if (app_id) {
      setAppid(app_id)
    }
  }, [])



  return <Router />

  // return <React.StrictMode>
  //   <Router />
  // </React.StrictMode>
}


const root = ReactClient.createRoot(document.getElementById('root'))

root.render(
  <App />

);
// ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
