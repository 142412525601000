import InnerHeader from "../../components/InnerHeader"

import { ReactComponent as SvgRight } from "../../svg/right.svg"

const SettingItem = ({ title, onClick, icon, style }) => {
    return <div onClick={onClick} style={{
        display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", boxSizing: "border-box", padding: "0.16rem 0rem", borderBottom: "1px solid #ccc"
        , fontSize: "0.14rem", ...style
    }}>
        <div>{title}</div>
        {icon}
    </div>
}

export default () => {

    return <div>
        <InnerHeader title={'設置中心'} showBottomLine={true} />

        <div style={{ width: "96%", margin: 'auto' }}>
            <SettingItem title={`充值記錄`} onClick={() => { window.location.href = '#/rechargelog' }} icon={<SvgRight fill="#ccc" style={{ width: "0.2rem", height: "0.2rem" }} />} />
            <SettingItem title={`消費記錄`} onClick={() => { window.location.href = '#/consumelog' }} icon={<SvgRight fill="#ccc" style={{ width: "0.2rem", height: "0.2rem" }} />} />
            <SettingItem title={`修改密碼`} onClick={() => { window.location.href = '#/changepassword' }} icon={<SvgRight fill="#ccc" style={{ width: "0.2rem", height: "0.2rem" }} />} />
            {/* <SettingItem title={`綁定手機號`} onClick={() => { window.location.href = '#/bindphone' }} icon={<SvgRight fill="#ccc" style={{ width: "0.2rem", height: "0.2rem" }} />} /> */}
        </div>
    </div>
}