import { CSSProperties } from "react"
import "./index.css"



/**
 * 
 * @param {{style:CSSProperties,showBottomLine:boolean,title:any,rightIcon:any}} param0 
 * @returns 
 */
const InnerHeader = ({ style, title, showBottomLine, onBack, rightIcon }) => {
    
    return <div className={`header   ${showBottomLine === true ? 'bottom-line' : ''}`} style={{ maxWidth:"100%",overflow:"hidden",...style, background:"#fe7a2e" }}>
        <div className='' style={{ paddingTop: "0.04rem", paddingBottom: ".04rem" ,display:"flex",flexDirection:"row",alignItems:'center',justifyContent:'space-between'}}  >
            <div className='header-left btn' onClick={() => onBack ? onBack?.() : window.history.back()}>
                <img src={require("../../icons/back.png")} style={{ height: "0.3rem", height: "0.3rem" }} />
            </div>
            <div  style={{ fontWeight: 'bold', color: "#fff" ,textAlign:"left", width:"80%"}}>{title}</div>
            <div className='header-right'>{rightIcon}</div>
        </div>
    </div>
}

export default InnerHeader