export default () => {



    return <div style={{ width: "96%", margin: "auto" }}>
        <div style={{ textAlign: 'center', fontWeight: 'bold' ,padding:"0.1rem 0rem"}} className="primary-color">
            &copy; 遊戲充值中心
        </div>
    </div>



}