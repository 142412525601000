const TjId = process.env.REACT_APP_TJ;
export default () => {


    const loadTj = () => {
        TjId && window?.LA?.init?.({ id: TjId, ck: TjId })

    }

    return { loadTj }

}