import { ReactComponent as SVGMoney } from "../../svg/qianbao4.svg"
import { ReactComponent as SVGCoin } from "../../svg/jinbi2.svg"
export default ({ coin, money, desc, onClick }) => {


    return <div onClick={onClick} style={{
        width: "1.05rem", height:"1.1rem", border:"2px solid #ef7129",  background:"#fff"
        , borderRadius: "0.08rem", display: "flex", flexDirection: "column", alignItems: "center",
        padding: "0.06rem", boxSizing: 'border-box', position: 'relative',   justifyContent: "center", overflow: "hidden", marginBottom: "0.08rem"

    }}>
        {/* <img style={{ width: "80%", position: 'absolute', opacity: 0.4, left: "-0.6rem", bottom: "-0.1rem", filter: "blur(2px)" }} src={require("../../images/money.png")} /> */}
        <div style={{
            position: 'relative',  width:"100%",marginTop:"0.2rem",
            color: "#a76e03", display: "flex", flexDirection: "row", alignItems: 'center',height:"0.2rem", textAlign:"center", justifyContent:"center"

        }}> 
        <SVGCoin style={{ width: "0.14rem", height: "0.14rem", marginRight: "0.04rem" }} /> <div style={{ fontWeight: "bold", fontSize: "0.16rem", lineHeight: "0.14rem", color: "rgb(250,174,18)" }}>{coin}</div>
        </div>


        <div style={{ position: "relative", display: "flex", flexDirection: "row",  width: "100%", marginTop:"0.15rem",height:"0.6rem", boxSizing:"border-box" }}>

            <div style={{   position: "relative", flex: 1 }}>
                <span  style={{ fontSize: "0.08rem", color:"#333" }}>實付：<span style={{fontSize:"0.12rem", color:"red"}}>{(money / 100).toFixed(2)} <span style={{fontSize:"0.08rem"}}>元</span></span></span>
                <div style={{ fontSize: "0.09rem",height:"0.3rem",marginTop:"0.05rem", color: "#1296db",  lineHeight: "0.16rem",
                 height: "0.16rem" }}>{desc}</div>
            </div>
        </div>


    </div>

}