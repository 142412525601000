import { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import PayChannelItem from "../../components/PayChannelItem"
import CurrencyItem from "../../components/CurrencyItem"
import PayMoneyItem from "../../components/PayMoneyItem"
import UserInfoPanel from "../../components/UserInfoPanel"
import useAPI from "../../hooks/common/useAPI"
import useSDK from "../../hooks/data/useSDK"

import { Toast,Tabs,NoticeBar } from "antd-mobile"
import InnerHeader from "../../components/InnerHeader"
import KeFu from "../../components/KeFu"

export default () => {


    const { request } = useAPI()

    const [userinfo, setUserinfo] = useState()

    const { getAppid, canExit, exit } = useSDK()
    const getUserinfo = async () => {

        let rs = await request("/user/info")
        if (rs?.result === true) {
            setUserinfo(rs?.data)
            return true;
        } else {
            Toast.show(rs?.message)
            return false;
        }

    }

    useEffect(() => {
        getUserinfo();
    }, [])








    const [selectedPayChannel, setSelectedPayChannel] = useState()
    const [selectedCny, setSelectedCny] = useState()

    // const paychanels = [
    //     {
    //         id: "1",
    //         iocn: require("../../icons/wechat.png"),
    //         title: "微付支付",
    //         desc: "推荐使用微信支付付款",
    //         disabled: 1
    //     },
    //     {
    //         id: "2",
    //         iocn: require("../../icons/alipay.png"),
    //         title: "支付宝",
    //         desc: "安全快捷的支付方式",
    //         disabled: 0
    //     },
    // ]

    const [paychannels, setPaychannes] = useState()
    const [cnyList, setCnyList] = useState()

    const getPayChannels = async () => {
        let rs = await request("/pay/channels3", {
            app_id: getAppid()
        })

        if (rs?.result === true) {

            setCnyList(rs?.data)
            let item = rs?.data?.[0]
            let list = item?.list
            setSelectedCny(item?.id);



            setPaychannes(list)
            let pay = list?.find(t => t.is_default === 1)
            setSelectedPayChannel(pay?.id);

        } else {
            Toast.show(rs?.message)
        }
    }

    const [notice, setNotice] = useState()
    const getNotice = async ()=>{
        let rs = await request("/app/mallNote", {
            app_id: getAppid()
        })

        if (rs?.result === true) {

            setNotice(rs?.data?.mallNote)

        }
    }

    const clickCny = (key)=> {
        let id = Number(key)
        let item = cnyList?.find(t=>t.id === id)
        let list = item?.list
        setSelectedCny(item?.id);



        setPaychannes(list)
        let pay = list?.find(t => t.is_default === 1)
        if(!pay){
             pay = list?.[0]
        }
        setSelectedPayChannel(pay?.id);
        // getMoneys()
    }


    useEffect(() => {
        getPayChannels()
        getNotice()
    }, [])



    const [moneys, setMoneys] = useState()

    const getMoneys = async () => {
        let rs = await request("/pay/moneys", { app_id: getAppid(), pay_channel_id: selectedPayChannel })
        if (rs?.result === true) {
            setMoneys(rs?.data)
        } else {
            Toast.show(rs?.message)
        }
    }

    useEffect(() => {
        if (!selectedPayChannel) return;
        getMoneys();
    }, [selectedPayChannel])




    return <div style={{ paddingBottom: "1rem" }}>
        {/* <Header /> */}
        {/* <InnerHeader title={`充值中心`} onBack={exit} />  */}

        <UserInfoPanel

            onBack={exit}
            onRefresh={async () => {
                let rs = await getUserinfo();
                if (rs) Toast.show('信息已更新')
            }} name={userinfo?.username} coins={(userinfo?.money / 100).toFixed(2)} face_img={require("../../images/faceimg.png")} />


<div style={{ marginTop:"0.1rem"}}>
    {selectedCny ? 
    <Tabs onChange={(key) => { clickCny(key) }} activeLineMode='full'>
    {cnyList?.map(function(t){
        return <>
          <Tabs.Tab title={<>
          <div style={{ display: 'flex', flexDirection: "row", justifyContent:"space-between", flexWrap:"wrap" }}>
            <div style={{ display: 'flex', flexDirection: "flex", justifyContent:"center"}} ><img style={{width:"0.3rem"}} src={t.icon}/><span style={{marginTop:"3px", marginLeft:"2px"}}>{t.name}</span></div>
          </div>
          </>} key={t.id}></Tabs.Tab>
          
        </>
    })}
    </Tabs>
    :''}
    
</div>


        


        <div style={{
            width: "96%", margin: 'auto',
            marginTop: "0.1rem", fontWeight: "bold", fontSize: "0.18rem"
        }}>
            <div>支付方式</div>
        </div>


        <div style={{ margin: "auto", marginTop: "0.1rem", display: 'flex', flexDirection: "row", justifyContent:"flex-start", flexWrap:"wrap" }}>
            {
                paychannels?.map(function (t) {
                    return <>
                    <PayChannelItem key={t.id} icon={t.icon}
                    title={t.name}
                    type={t.big_paytype}
                    desc={t.desc}
                    checked={selectedPayChannel === t.id}
                    onClick={() => {
                        setSelectedPayChannel(t.id)
                    }}
                    disabled={t.enabled === 0}
                />
                
                {/* 测试框： <PayChannelItem key={3} icon={t.icon}
                title={t.name}
                type={'wx'}
                desc={t.desc}
                checked={selectedPayChannel === 3}
                onClick={() => {
                    setSelectedPayChannel(3)
                }}
                disabled={t.enabled === 0}
            /> */}
                    </>

                })

            }


        </div>

        {notice && <NoticeBar content={notice}
         color='alert'  speed={30} className='notice-bar' style={{color:"red", fontWeight:"bold", fontFamily:"cursive"}} />}
        
        {moneys?.map(t => {
            return <div key={t.id}>

                <div style={{
                    width: "96%", margin: 'auto',
                    marginTop: "0.1rem", fontWeight: "bold", fontSize: "0.16rem"
                }}>
                    <div>{t.name}</div>
                </div>


                <div style={{ width: "96%", margin: "auto", marginTop: "0.1rem", display: 'flex', flexDirection: "row", flexWrap: "wrap", alignItems: 'center', justifyContent: "space-around" }}>
                    {t?.list?.map(s => {
                        return <>
                            <PayMoneyItem key={s.id} desc={s.desc}
                            money={s.pay_money}
                            coin={`${(s.money / 100).toFixed(2)}`}
                            onClick={() => {
                                window.location.href =
                                    `#/order_confirm?money_id=${s.id}&pay_channel_id=${selectedPayChannel}&money=${s.money}&pay_money=${s.pay_money}&paytype=${paychannels.find(t => t.id === selectedPayChannel)?.name}`
                            }}

                        />

                        </>
                    })}
                    
                    <div style={{width:"1rem"}}></div>
                    <div style={{width:"1rem"}}></div>


                    


                </div>
            </div>
        })}
{/* {(userinfo && !userinfo?.phone) && <div style={{ width: "96%", margin: "auto", marginTop: "0.1rem", display: 'flex', flexDirection: "row", justifyContent: "center" }}>
            <div style={{ backgroundColor: "#ab731f", padding: "0.1rem 0.3rem", lineHeight: "0.16rem", borderRadius: "1rem", fontWeight: 'bold', fontSize: "0.12rem", color:"#fff" }}
                onClick={() => window.location.href = "#/bindphone"}
            >綁定手機號，立即贈送5元。<span style={{ fontSize: "0.16rem" }}>👆</span></div>
        </div>} */}

        <Footer />
        <KeFu />

    </div>
}