import { ReactComponent as SVGCoin } from "../../svg/jinbi2.svg"
import { ReactComponent as SVGRefresh } from "../../svg/refresh2.svg"
import { ReactComponent as SVGMenu } from "../../svg/menu.svg"

export default ({ name, coins, face_img, onRefresh, onBack }) => {

    return <div  style={{height:"1.5rem",
        width: "100%", margin: 'auto',  paddingTop: "0.2rem",  background:"#fe9438",
        justifyContent: "center"
    }}>


                <div style={{ margin:"auto", width: "0.4rem", height: "0.4rem", backgroundColor: '#ccc', borderRadius: "1rem", overflow: "hidden", border: "2px solid white" }}>
                    <img src={face_img} style={{ width: "100%", height: "100%" }} />
                </div>
            <div style={{margin:"auto",
                color: "white", fontSize: "0.18rem", maxWidth: "1.6rem", lineHeight: "0.28rem",
                maxHeight: "0.56rem", overflow: "hidden",
                textAlign: 'center'
            }}>{name}</div>


        <div style={{width:"3rem", background:"#", height:"0.5rem", margin:"auto", color:"#fff"}}>
            <span style={{width:"1rem", paddingLeft: "0.6rem", fontSize: "0.14rem", lineHeight: "0.5rem" , textAlign:"right" }}>當前餘額：</span>
            <span style={{ width:"1rem", paddingLeft: "0rem", fontSize: "0.24rem", lineHeight: "0.5rem" , textAlign:"right"}}>{coins}</span>
            <span style={{ justifyContent:"end", flexDirection:"column", justifyItems:"center", height:"0.5rem" }}>
            <SVGCoin style={{ width: "0.18rem", height: "0.18rem" }} />
            </span>
        </div>

        

        <SVGMenu onClick={() => { window.location.href = "#/setting" }} className="btn" fill="#fff" style={{ width: "0.24rem", height: "0.24rem", marginLeft: "0.02rem", position: "absolute", right: "0.1rem", top: "0.1rem" }} />
            <img src={require("../../icons/back.png")} onClick={() => {
                if (onBack) {
                    onBack?.()
                } else {
                    window.history.back();
                }
            }} style={{ width: "0.24rem", height: "0.24rem", marginLeft: "0.02rem", position: "absolute", left: "0.1rem", top: "0.1rem" }} />
    </div>
}