import { List, Toast } from "antd-mobile"
import { useEffect, useState } from "react"
import InnerHeader from "../../components/InnerHeader"
import useAPI from "../../hooks/common/useAPI"
import useOnReady from "../../hooks/common/useOnReady"

import { ReactComponent as SVGMore } from "../../svg/down.svg"
import { ReactComponent as SVGNodata } from "../../svg/nodata.svg"


const ListItem = ({ data }) => {
    return <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', padding: "0.1rem 0rem", borderBottom: "1px solid #ccc" }}>
        <div style={{ lineHeight: "0.18rem", flex: 1, overflow: "hidden" }} >
            <div style={{ fontWeight: "",color:"#333", fontSize: "0.14rem", maxHeight: "0.2rem", overflow: "hidden", boxSizing: "border-box", marginRight: "0.1rem", textOverflow: "ellipsis", wordBreak: "break-all", whiteSpace: "nowrap" }}>{data?.prop_name}</div>
            <div style={{ fontSize: "0.13rem", color: "#666",marginTop:"0.04rem" }}>餘額：{(data?.after_money / 100).toFixed(2)}</div>
            <div style={{ fontSize: "0.13rem", color: "#666" }}>{new Date(data?.create_time).format("yyyy-MM-dd HH:mm:ss")}</div>
        </div>
        <div style={{ boxSizing: "border-box", padding: "0rem 0rem", }}>
            <div style={{ fontSize: "0.18rem", fontWeight: '', color:"#666" }}>-{(data?.money / 100).toFixed(2)}</div>

        </div>

    </div>

}

export default () => {

    const { request } = useAPI()
    const { ready, readyed } = useOnReady()
    const [data, setData] = useState()
    const [pager, setPager] = useState({ current: 1, total: 0, pageSize: 20 })
    const getPage = async (page) => {
        let rs = await request("/consume/consume_record", { page, pageSize: pager.pageSize, })
        if (rs?.result === true) {
            ready();
            if (parseInt(page) === 1) {
                setData(rs?.data?.data)

            } else {
                setData([...data, ...rs?.data?.data])

            }
            setPager(rs?.data?.pager)

        } else {
            Toast.show(rs?.message)
        }
    }


    useEffect(() => {
        getPage(1)
    }, [])

    return <div>
        <InnerHeader title={`消費記錄`} />
        <div style={{ width: "96%", margin: "auto" }}>
            {data?.map(t => {
                return <ListItem data={t} key={t?.id} />
            })}
        </div>
        {(pager.pageSize * pager.current < pager.total) && <div style={{ display: 'flex', justifyContent: 'center', marginTop: "0.2rem",marginBottom:"0.4rem" }}>
            <div className="btn" onClick={() => { getPage(parseInt(pager.current) + 1) }} style={{ display: "flex", alignItems: 'center', fontSize: "0.14rem", backgroundColor: "rgba(0,154,214,0.8)", boxSizing: "border-box", padding: "0.12rem 0.5rem", color: "white", borderRadius: "0.04rem" }}>
                <div>加载更多</div>
                &nbsp;<SVGMore fill="#fff" style={{ width: "0.18rem", height: "0.18rem" }} />
            </div>
        </div>}

        <div style={{ marginTop: "0.4rem", textAlign: 'center' }}>
            {(readyed && data?.length > 0 === false) && <div>
                <SVGNodata style={{ width: "1.2rem", height: "1.2rem", margin: "auto" }} />
                <div style={{ fontSize: "0.2rem", color: "#888" ,marginTop:"-0.16rem"}}>暂无数据</div>
            </div>}
        </div>
    </div>
}