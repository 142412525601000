

import { ReactComponent as SVGChecked } from "../../svg/checked.svg"
import "./index.css"
export default ({ icon, title, type,  desc, checked, disabled, onClick }) => {



    return <div onClick={() => {


        if (!disabled) {
            onClick?.();
        } else {
            //不触发事件
        }

    }} style={{
        
    }}>

        <div  className = {`icons ${checked === true ?" checked-way":"nochecked-way"} ${type === "alipay" ? "font-ali":"font-wx"}`} >
                <div style={{width:"0.4rem", height:"0.6rem",display:"flex", flexDirection:"column", justifyContent:"center"}}>
                    <div style={{ background:"#fff", width: "0.3rem", height: "0.3rem", borderRadius:"0.3rem", border:"2px solid #fff"}}>
                    <img src={icon} style={{ width: "0.3rem", height: "0.3rem" }} />
                        {/* <img src={require('../../icons/wechat.png')} style={{ width: "0.3rem", height: "0.3rem" }}/> */}
                    </div>
                </div>
                <div style={{width:"1rem", height:"0.6rem",display:"flex", flexDirection:"column", justifyContent:"center"}}>
                    <div style={{ fontWeight: "bold" }}>{title}</div>
                    <div style={{ fontSize: "0.11rem", marginTop: "0.03rem" }}>{desc}</div>
                </div>
        </div>
        

        {/* <div style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
            <img src={icon} style={{ width: "0.26rem", height: "0.26rem" }} />
            <div style={{ marginLeft: "0.04rem" }}>
                <div style={{ fontWeight: "bold" }}>{title}</div>
                <div style={{ fontSize: "0.11rem", color: "#888", marginTop: "0.03rem" }}>{desc}</div>

            </div>
        </div>

        <div style={{ position: 'absolute', right: "0.04rem", top: "0.04rem" }}>
            {checked === true && <SVGChecked style={{ width: "0.24rem", height: "0.24rem" }} fill="rgb(9,187,7)" />}
            {checked === false && <SVGChecked style={{ width: "0.24rem", height: "0.24rem" }} fill="#ccc" />}
        </div> */}

    </div>
}