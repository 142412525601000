import { useState, useEffect, useRef } from 'react';
import { fromJS, is } from 'immutable';



//安全的setState  检测到hook unmount之后，不再setState 防止报错。
export default (defaultValue, isDebug = false) => {

    const [state, setStateNoSafe] = useState(defaultValue);


    const refPrev = useRef(undefined);

    useEffect(() => {
        refPrev.current = fromJS(state);
    }, [state])

    const setImmetableState = (newstate) => {
        if (is(fromJS(newstate), refPrev.current)) {
            return;
        };

        setStateNoSafe(newstate);
    }

    const refState = useRef({
        compnentIsUnmounted: false
    })

    useEffect(() => {
        refState.current.compnentIsUnmounted = false;
        return () => {
            if (isDebug === true) {
                // console.log('unmount', state)
            }
            refState.current.compnentIsUnmounted = true;
        }
    }, [])

    const setState = (val) => {
        if (isDebug) {
            // console.log('setState', state, val)
            // console.log('compnentIsUnmounted', refState.current.compnentIsUnmounted)
        }
        if (refState.current.compnentIsUnmounted === false) {

            setImmetableState(val)
        } else {
        }
    }
    return [state, setState];
}