import { Button, Modal, SpinLoading, Toast } from "antd-mobile";
import { useState } from "react";
import { useParams } from "react-router"
import { useSearchParams } from "react-router-dom";
import InnerHeader from "../../components/InnerHeader"
import useAPI from "../../hooks/common/useAPI";
import useSDK from "../../hooks/data/useSDK";
import { ReactComponent as SVGSuccess } from "../../svg/success.svg"
export default () => {

    const [params, setParams] = useSearchParams();

    const back = params.get("back");

    const { exit } = useSDK()
    let onBack = () => window.history.back();
    if (back === "app") {
        onBack = () => {
            exit?.()
        }
    }

    const showSuccess = () => {
        const handler = Modal.show({
            maskStyle: { backgroundColor: "#000", opacity: 0.8 },
            content: <div style={{ textAlign: 'center' }}>

                <div style={{ fontSize: "0.16rem", fontWeight: 'bold' }}>修改密碼成功</div>
                <SVGSuccess style={{ width: "0.8rem", height: "0.8rem", marginTop: "0.2rem" }} />
                <div className="primary-bg btn" style={{
                    margin: 'auto', marginTop: "0.2rem", width: "96%", display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: "center", color: "white", height: "0.4rem"
                    , borderRadius: "0.04rem"
                }} onClick={() => {
                    handler.close();
                    onBack?.();
                }}>
                    點擊返回
                </div>
            </div>
        })
    }
    const [isLoading, setIsLoading] = useState(false)
    const { request } = useAPI()
    const [oldPassword, setOldPassword] = useState()
    const [password, setPassword] = useState();
    const [password2, setPassword2] = useState();
    const changePassword = async () => {



        if (!oldPassword) return Toast.show('請輸入原密碼')
        if (!password) return Toast.show('請輸入新密碼')
        if(password!==password2) return Toast.show('兩次輸入的密碼不一致')
        setIsLoading(true)
        let rs = await request("/user/change_password", { old_password: oldPassword, new_password: password });
        if (rs?.result === true) {
            // Toast.show('修改成功')
            // onBack?.()
            showSuccess();

        } else {
            Toast.show(rs?.message)
        }
        setIsLoading(false)

    }

    return <div>
        <InnerHeader title={`修改密碼`} onBack={onBack} />
        <div style={{ width: "90%", margin: "auto", boxSizing: "border-box" }}>
            {/* <div style={{ fontSize: "0.3rem", marginTop: "0.3rem", color: "#333" }}>修改密碼</div> */}
            

            <div style={{ marginTop: "0.4rem" }}>
                <div>
                    <input value={oldPassword} onChange={e => setOldPassword(e.target.value)} placeholder="原密碼" type={'password'} style={{
                        height: "0.36rem", border: "1px solid #ccc", borderRadius: "0.04rem", boxSizing: "border-box", paddingLeft: "0.04rem", paddingRight: "0.04rem"
                        , width: "100%"
                    }} />
                </div>
                <div style={{ marginTop: "0.24rem" }}>
                    <input value={password} onChange={e => setPassword(e.target.value)} placeholder="新密碼" type={'password'} style={{
                        height: "0.36rem", border: "1px solid #ccc", borderRadius: "0.04rem", boxSizing: "border-box", paddingLeft: "0.04rem", paddingRight: "0.04rem"
                        , width: "100%"
                    }} />
                </div>
                <div style={{ marginTop: "0.24rem" }}>
                    <input value={password2} onChange={e => setPassword2(e.target.value)} placeholder="再次輸入新密碼" type={'password'} style={{
                        height: "0.36rem", border: "1px solid #ccc", borderRadius: "0.04rem", boxSizing: "border-box", paddingLeft: "0.04rem", paddingRight: "0.04rem"
                        , width: "100%"
                    }} />
                </div>

                <div style={{ fontSize: "0.14rem", marginTop: "0.04rem", color: "#333" }}>8-16比特，至少包含數位、字母、特殊字元中的兩類</div>
                <div style={{ marginTop: "0.4rem" }}>
                    <div className=" btn" style={{background:"#fe7a2e",
                        width: "100%", height: "0.4rem", display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center', color: 'white',
                        borderRadius: "0.04rem", margin: 'auto', fontSize: "0.14rem"
                    }} onClick={changePassword}>确认修改</div>
                </div>
            </div>
        </div>


        {isLoading === true && <div className="loading" style={{ position: "fixed", left: 0, right: 0, top: 0, bottom: 0, zIndex: 10 }} >
            <div style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, backgroundColor: "white", opacity: 0.5 }}></div>
            <div style={{ textAlign: "center", position: "relative", width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center' }}>
                <SpinLoading color={`rgba(0,154,214,1)`} />
                <div style={{ color: "rgba(0,154,214,1)", fontWeight: 'bold', marginTop: "0.16rem" }}>正加加载 ...</div>
            </div>
        </div>}
    </div>
}

