import { Toast } from "antd-mobile"
import useAPI from "../../hooks/common/useAPI"
import useSDK from "../../hooks/data/useSDK"
import { ReactComponent as SVGKeFu } from "../../svg/kefu.svg"
export default () => {

    const { request } = useAPI()
    const { getAppid } = useSDK()
    const goToKeFu = async () => {

        let rs = await request("/app/config", { app_id: getAppid() })
        if (rs?.result === true) {
            if (!rs?.data?.kefu_url) {
                return Toast.show('獲取配置失敗')
            }
            window.location.href = rs?.data?.kefu_url;

        } else {
            Toast.show(rs?.message)
        }

    }

    return <div className="kefu btn" onClick={goToKeFu} style={{
        position: 'fixed', right: "0.16rem",
        bottom: "0.8rem", backgroundColor: "white", borderRadius: "1rem", overflow: "hidden", padding: "0.1rem"

        , boxShadow: "0px 0px 20px #ccc", display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center'
    }}>
        <SVGKeFu style={{ width: "0.46rem", height: "0.46rem" }} />
        {/* <div>联系客服</div> */}
    </div>
}