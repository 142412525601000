import { Route, HashRouter, Routes, useSearchParams,useLocation } from "react-router-dom";
// import { Routes } from "react-router";


import { useEffect } from "react";



const RenderComponent = (Comp) => {
    return <Comp />
}

const Router = () => {



     

    // const {}=useApi

    // const token = params.get("token");
    // console.log('token', token)






    return <HashRouter>
        <Routes>
            <Route path="/" element={RenderComponent(require("./Index").default)} />
            <Route path="/order_confirm" element={RenderComponent(require("./OrderConfirm").default)} />
            <Route path="/setting" element={RenderComponent(require("./Setting").default)} />
            <Route path="/rechargelog" element={RenderComponent(require("./RechargeLog").default)} />
            <Route path="/consumelog" element={RenderComponent(require("./ConsumeLog").default)} />
            <Route path="/changepassword" element={RenderComponent(require("./ChangePassword").default)} />
            <Route path="/bindphone" element={RenderComponent(require("./BindPhone").default)} />
            {/* <Route path="/" element={RenderComponent(require("./Test").default)} /> */}
 
        </Routes>
    </HashRouter>
}

export default Router;